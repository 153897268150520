// import social1 from '@/asset/社会价值/社会价值1.jpg'
// import social2 from '@/asset/社会价值/社会价值2.jpg'
// import social3 from '@/asset/社会价值/社会价值3.jpg'

export const socialValueImgs = {
  social1: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/BA3vQVhacnMbnyntpeSw7g.jpg',
  social2: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/ng2JLFtKQEtRzr2477.jpg',
  social3: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/8OOX7loCHnpsIqr1GPUM2.jpg',
  social4: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/snFmqLbOre8yuWBNXiRT.png',
}
