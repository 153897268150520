import { isMobile } from '@/utils/common'

export const bussinessImgs = isMobile() ? {
  banma: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/yOtuiMyBVuVWbUhKgMgAM.png',
  feixiang: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/8Pisz7IsHgteWfiQUcOXyJ.png',
  moli: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/lk21O9u8mYS02ILig7tzNn.png',
  nangua: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/6QttrKCvZC3EJQvfBx3iYq.jpg',
  sky: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/Bx6TkowZIJzl9dgydXF7.png',
  kousuan: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/N1XLxRWPoPDq0tBBu1LLZw.png',
  biancheng: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/fYDjhPkRtTEbKNI9z9l.png',
  fudao: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/zgBzDrICASyXhpgXeCj22v.png',
  tiku: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/Il6tbBmPsB6NZ0dN8Gj9.png',
  coffee: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/ODUX6SkBH2KzouEX7ZPjW.png',
  haitun: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/CK6akkhHM0dY9PwgoR4XE7.png',
  suyangke: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/UlLLqlRSbdgE15mp9JiPt.png',
} : {
  banma: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/bA1hK48Ss4q3COHw4Pwtg.png',
  feixiang: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/LX2bXB6eiz6UfHd8RZIwv.jpg',
  moli: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/jqGbszyEGw6jhhFHpPnyjB.png',
  nangua: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/6QttrKCvZC3EJQvfBx3iYq.jpg',
  sky: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/TLu3Zr9533qEZFWA3Phxhs.jpg',
  kousuan: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/TlVNhiPPh3LvlYTBSxxBt.jpg',
  biancheng: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/2OxQXByt4rdhHNZk126J.jpeg',
  fudao: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/jzBp39KhEC2OttPeQrGsl.jpg',
  tiku: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/WykjV7IqEJUqhVgw3DDxI.jpg',
  coffee: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/GnmLLmAqzVaKTVppHjcT.png',
  haitun: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/iOShVUlobewXqvuvGoQL9u.jpeg',
  suyangke: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/UlLLqlRSbdgE15mp9JiPt.png',
}
